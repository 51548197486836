import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet"
import Reveal from "../components/reveal"
import { motion } from "framer-motion"

function Postcard({ title, slug, img, subtitle }) {
  return (
    <Reveal cc="py-5 px-4 rounded-lg hover:bg-opacity-70 hover:bg-realGray-900">
      <div className="h-full flex flex-col sm:flex-row md:px-1 sm:items-center">
        <Link
          to={`/blog/${slug}`}
          className="rounded-md w-44 order-2 sm:ml-auto"
        >
          <GatsbyImage image={img.childImageSharp.gatsbyImageData} className="rounded-md" />
        </Link>
        <div className="flex flex-col">
          <div className="mb-3">
            <Link to={`/blog/${slug}`} className="">
              <motion.h1
                className="font-normal text-xl inline-block mt-4"
              >
                {title}
              </motion.h1>
            </Link>
            <h3 className="font-normal text-sm text-realGray-400 hidden">
              {subtitle}
            </h3>
          </div>
          {/* <span className="text-xs font-medium text-gray-400 mt-8 mb-2 uppercase">{timeToRead} minutes to read</span> */}
        </div>
      </div>
    </Reveal>
  );
}

export default function Insights({ data, location }) {
  const isOn = false

  const categories = ["All", "Branding", "Lettering"]
  const [selectedCategory, setCategory] = useState("All")

  function handleCategories({ node }) {
    if (selectedCategory === "All") return true
    else return node.frontmatter.category === selectedCategory
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Lance – Blog"></meta>

        <title>Lance / Insights</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="scrollSmooth" />
      </Helmet>

      {isOn ? (
        <>
          <div className="min-h-hero mb-12 flex justify-center items-center">
            <h1 className=" font-normal text-center text-5xl mb-3">Insights</h1>{" "}
            <span className="text-sm py-1 px-2 bg-realGray-900 text-realGray-300 rounded-sm ml-3">
              {" "}
              Coming Soon
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col md:mt-24 mb-40 max-w- mx-auto">
            <div className="flex flex-wrap flex-row col-start-1 col-end-3 sticky top-8 self-start md:mt-0 mt-12 mb-6 z-10">
              {categories.map((i, index) => (
                <button
                  key={index}
                  onClick={e => setCategory(e.target.textContent)}
                  className={
                    i === selectedCategory
                      ? "widthFitContent border border-white border-solid rounded-full px-2 py-1 currentColor inline-block text-xs font-medium bg-black uppercase mr-2"
                      : "widthFitContent border border-white border-solid rounded-full px-2 py-1 inline-block text-xs text-white font-medium bg-black uppercase mr-2"
                  }
                >
                  {i}
                </button>
              ))}
            </div>

            <div className="grid grid-cols-3 gap-x-3 col-start-3 col-end-13">
              {data.allMdx.edges.filter(handleCategories).map(({ node }) => (
                <Postcard
                  key={node.id}
                  slug={node.frontmatter.slug}
                  title={node.frontmatter.title}
                  category={node.frontmatter.category}
                  img={node.frontmatter.featuredImage}
                  subtitle={node.frontmatter.subtitle}
                  timeToRead={node.timeToRead}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const query = graphql`{
  allMdx(
    filter: {frontmatter: {type: {eq: "post"}}}
    sort: {order: DESC, fields: frontmatter___date}
  ) {
    edges {
      node {
        id
        timeToRead
        frontmatter {
          title
          slug
          category
          subtitle
          featuredImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
